.features-blur-image {
  aspect-ratio: auto 1132 / 1412;
}
.feature-container {
  aspect-ratio: 1132 / 1412;
  overflow: hidden !important;
  text-align: center;
  align-items: center;
  justify-content: center;
}
